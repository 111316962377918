<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    enviar: {
      name: { required },
      person_type: { required },
      person_number: { required },
      bank: { required },
      agency: { required },
      agency_digit: {},
      account: { required },
      account_digit: { required },
      account_type: { required },
      value: { required },
      description: {},
    }
  },
  data() {
    return {
      bank: {
        code: '',
        label: '',
      },
      banks: [],

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },

      receber: {
        modal: false,
      },

      enviar: {
        modal: false,
        progress: 1,

        name: "",
        person_type: 1,
        person_number: "",
        bank: "",
        agency: "",
        agency_digit: "",
        account: "",
        account_digit: "",
        account_type: 1,

        value: "",
        tax: "0.00",
        description: "",

        id: "",
        code: "",

        show: false,
        balance: 'R$ ***,**',
      },
    };
  },
  directives: { money: VMoney },
  methods: {
    getBancos() {
      api.get("transferir/bancos").then((response) => {
        if (response.data.status == "success") {
          this.banks = response.data.list;
        }
      })
    },
    copyBank() {
      navigator.clipboard.writeText(this.account.user.bank.copy);
      this.$toast.success('Dados copiados.');
    },
    getSaldo() {
      api.get("extrato/saldo").then((response) => {
        if (response.data.status == "success") {
          this.enviar.balance = this.$options.filters.currency(response.data.balance);
        }
      });
    },
    enviarTransferencia() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.enviar.progress = 5;

        api
          .post("transferir", {
            bank: this.enviar.bank,
            name: this.enviar.name,
            person_number: this.enviar.person_number,
            agency: this.enviar.agency,
            agency_digit: this.enviar.agency_digit,
            account: this.enviar.account,
            account_digit: this.enviar.account_digit,
            account_type: this.enviar.account_type,
            value: this.enviar.value,
            description: this.enviar.description,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.enviar.bank = "";
              this.enviar.name = "";
              this.enviar.person_number = "";
              this.enviar.agency = "";
              this.enviar.agency_digit = "";
              this.enviar.account = "";
              this.enviar.account_digit = "";
              this.enviar.account_type = "";
              this.enviar.value = "";
              this.enviar.description = "";
              this.$v.$reset();

              this.enviar.progress = 6;
              this.enviar.id = response.data.transferir.id
            } else {
              this.enviar.progress = 4;
              this.$toast.error(response.data.message);
            }
          });
      }
    },
    confirmarTransferencia() {
      this.enviar.progress = 7;

      api
        .post("transferir/token", {
          id: this.enviar.id,
          code: this.enviar.code,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.enviar.id = "";
            this.enviar.code = "";

            this.enviar.progress = 9;
          } else {
            if (response.data.token && response.data.token == 'invalid') {
              this.enviar.progress = 6;
            } else {
              this.enviar.progress = 8;
            }

            this.$toast.error(response.data.message);
          }
        });
    },
    clearTransferencia() {
      this.bank.code            = "";
      this.bank.label           = "";
      this.enviar.modal         = false;
      this.enviar.bank          = "";
      this.enviar.name          = "";
      this.enviar.person_number = "";
      this.enviar.agency        = "";
      this.enviar.agency_digit  = "";
      this.enviar.account       = "";
      this.enviar.account_digit = "";
      this.enviar.account_type  = "";
      this.enviar.value         = "";
      this.enviar.description   = "";
      this.enviar.id            = "";
      this.enviar.code          = "";
      this.$v.$reset();
    }
  },
  mounted() {
    this.getBancos();
    this.getSaldo();
  },
  watch: {
    'bank': function (v) {
      this.enviar.bank = v.code
    },
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Transferir</h3>
        <p>Faça transações instantâneas com a sua conta digital.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="card pix-receber">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <h4>Receber</h4>
              <span class="bg-soft-default p-2 rounded"><i
                  class="bx bx-down-arrow-alt font-size-22 align-middle"></i></span>
            </div>
            <p>Compartilhe os dados da sua conta para receber um valor.</p>
            <hr>
            <button class="btn btn-outline-default" v-on:click="receber.modal = true">Cobrar alguém</button>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card pix-enviar">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <h4>Enviar</h4>
              <span class="bg-soft-default p-2 rounded"><i
                  class="bx bx-up-arrow-alt font-size-22 align-middle"></i></span>
            </div>
            <p>Realize pagamentos e transferências em via TED.</p>
            <hr>
            <button class="btn btn-outline-default"
              v-on:click="clearTransferencia(); enviar.modal = true; enviar.progress = 1;">Enviar</button>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="receber.modal" modal-class="modal-right" hide-header hide-footer>
      <div>
        <h4>Cobrar alguém</h4>
        <p>Compartilhe os dados da sua conta bancária</p>

        <h4 class="mt-5 mb-3 text-default">{{ account.user.name }}</h4>
        <div v-if="account.user.bank && account.user.bank.status">
          <h5 class="mb-3 font-size-15">Banco: <span class="font-weight-normal">{{ account.user.bank.numero_banco }} –
              Money Plus, Instituição
              financeira Integrante Sistema de
              Pagamento (SPB)</span></h5>
          <h5 class="mb-3 font-size-15">Agência: <span class="font-weight-normal">{{ account.user.bank.agencia }}</span>
          </h5>
          <h5 class="mb-3 font-size-15">Conta: <span class="font-weight-normal">{{ account.user.bank.conta }}-{{
            account.user.bank.conta_digito
          }}</span>
          </h5>
          <button class="mt-5 btn btn-outline-default btn-block" v-on:click="copyBank()">
            <i class="bx bx-copy align-middle"></i>
            Copiar
          </button>
        </div>

        <div class="d-flex mt-5">
          <button class="btn btn-outline-link" v-on:click="receber.modal = false;">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="enviar.modal" modal-class="modal-right" hide-header hide-footer>
      <div>
        <h4 class="mb-4">Transferir</h4>
        <b-progress :value="enviar.progress" max="7" class="mb-5"></b-progress>

        <template v-if="enviar.progress == 1">
          <h4 class="mb-4">Qual a conta de destino?</h4>
          <b-form>
            <b-form-group id="bank" label="Banco" label-for="bank">
              <v-select v-model="bank" :options="banks"></v-select>
            </b-form-group>
            <b-form-group id="name" label="Nome Completo" label-for="name">
              <b-form-input id="name" v-model="enviar.name" type="text" autocomplete="off"
                :class="{ 'is-invalid': $v.enviar.name.$error }"></b-form-input>
            </b-form-group>
            <b-form-group id="person_number" label="CPF/CNPJ" label-for="person_number">
              <b-form-input id="person_number" v-model="enviar.person_number" type="text" autocomplete="off"
                :class="{ 'is-invalid': $v.enviar.person_number.$error }"></b-form-input>
            </b-form-group>
            <div class="row">
              <div class="col-8">
                <b-form-group id="bank" label="Agência" label-for="bank">
                  <b-form-input id="bank" v-model="enviar.agency" type="text" autocomplete="off"
                    :class="{ 'is-invalid': $v.enviar.agency.$error }"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group id="bank" label="Digito" label-for="bank">
                  <b-form-input id="bank" v-model="enviar.agency_digit" type="text" autocomplete="off"
                    :class="{ 'is-invalid': $v.enviar.agency_digit.$error }"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <b-form-group id="bank" label="Conta" label-for="bank">
                  <b-form-input id="bank" v-model="enviar.account" type="text" autocomplete="off"
                    :class="{ 'is-invalid': $v.enviar.account.$error }"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group id="bank" label="Digito" label-for="bank">
                  <b-form-input id="bank" v-model="enviar.account_digit" type="text" autocomplete="off"
                    :class="{ 'is-invalid': $v.enviar.account_digit.$error }"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <b-form-group id="account_type" label="Tipo da Conta" label-for="account_type">
              <select v-model="enviar.account_type" class="form-control"
                :class="{ 'is-invalid': $v.enviar.account_type.$error }">
                <option value="1">Conta Corrente</option>
                <option value="2">Conta Poupança</option>
                <option value="3">Conta Pagamento</option>
                <option value="4">Conta Salário</option>
              </select>
            </b-form-group>
          </b-form>
          <div class="d-flex mt-5">
            <b-button :disabled="!this.enviar.name ||
              !this.enviar.person_type ||
              !this.enviar.person_number ||
              !this.enviar.bank ||
              !this.enviar.agency ||
              !this.enviar.account ||
              !this.enviar.account_digit ||
              !this.enviar.account_type" variant="default" v-on:click="enviar.progress = 2">
              Continuar
            </b-button>
            <button class="btn btn-outline-link" v-on:click="clearTransferencia()">Cancelar
              <sup>ESC</sup></button>
          </div>
        </template>
        <template v-else-if="enviar.progress == 2">
          <h4 class="mb-4">Enviando para</h4>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-4 align-items-center">
                <h5>Transferindo para</h5>
                <span class="bg-soft-default p-2 rounded"><i
                    class="bx bx-up-arrow-alt font-size-20 align-middle"></i></span>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">Nome</h6>
                <h6 class="font-size-16 font-weight-normal">{{ enviar.name }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">CPF/CNPJ</h6>
                <h6 class="font-size-16 font-weight-normal">{{ enviar.person_number }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">Banco</h6>
                <h6 class="font-size-16 font-weight-normal">{{ enviar.bank }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">Agência</h6>
                <h6 class="font-size-16 font-weight-normal">
                  {{ enviar.agency }}<template v-if="enviar.agency_digit">-{{ enviar.agency_digit }}</template>
                </h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">Conta</h6>
                <h6 class="font-size-16 font-weight-normal">{{ enviar.account }}-{{ enviar.account_digit }}</h6>
              </div>
            </div>
          </div>
          <div class="d-flex mt-5">
            <b-button :disabled="!this.enviar.name ||
              !this.enviar.person_type ||
              !this.enviar.person_number ||
              !this.enviar.bank ||
              !this.enviar.agency ||
              !this.enviar.account ||
              !this.enviar.account_digit ||
              !this.enviar.account_type" variant="default" v-on:click="enviar.progress = 3">
              Continuar
            </b-button>
            <button class="btn btn-outline-link ml-3" v-on:click="enviar.progress = 1">Voltar</button>
            <button class="btn btn-outline-link" v-on:click="enviar.modal = false;">Cancelar
              <sup>ESC</sup></button>
          </div>
        </template>
        <template v-else-if="enviar.progress == 3">
          <h4 class="mb-4">E qual o valor?</h4>
          <b-form @submit.stop.prevent>
            <b-form-group id="value" label="Valor" label-for="value">
              <b-form-input id="value" v-model="enviar.value" v-money="money" type="text" autocomplete="off"
                :class="{ 'is-invalid': $v.enviar.value.$error }"></b-form-input>
            </b-form-group>
            <b-form-group id="description" label-for="description">
              <label for="description" class="d-block">Descrição <em class="text-light">(opcional)</em></label>
              <b-form-input id="description" v-model="enviar.description" type="text" autocomplete="off"
                :class="{ 'is-invalid': $v.enviar.description.$error }"></b-form-input>
            </b-form-group>
          </b-form>
          <div class="d-flex mt-5">
            <b-button :disabled="!this.enviar.name ||
              !this.enviar.person_type ||
              !this.enviar.person_number ||
              !this.enviar.bank ||
              !this.enviar.agency ||
              !this.enviar.account ||
              !this.enviar.account_digit ||
              !this.enviar.account_type ||
              !this.enviar.value ||
              this.enviar.value == 'R$ 0,00'" variant="default" v-on:click="enviar.progress = 4">
              Continuar
            </b-button>
            <button class="btn btn-outline-link ml-3" v-on:click="enviar.progress = 2">Voltar</button>
            <button class="btn btn-outline-link" v-on:click="clearTransferencia()">Cancelar
              <sup>ESC</sup></button>
          </div>
        </template>
        <template v-else-if="enviar.progress == 4">
          <h4 class="mb-4">Resumo da transferência</h4>
          <div class="d-flex justify-content-between align-items-center py-4">
            <h6 class="font-size-14 mb-0">Você está pagando</h6>
            <div class="d-flex align-items-center">
              <h6 class="font-size-16 font-weight-normal mb-0">{{ enviar.value }}</h6>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-4 align-items-center">
                <h5>Transferindo para</h5>
                <span class="bg-soft-default p-2 rounded"><i
                    class="bx bx-up-arrow-alt font-size-20 align-middle"></i></span>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">Nome</h6>
                <h6 class="font-size-16 font-weight-normal">{{ enviar.name }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">CPF/CNPJ</h6>
                <h6 class="font-size-16 font-weight-normal">{{ enviar.person_number }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">Banco</h6>
                <h6 class="font-size-16 font-weight-normal">{{ enviar.bank }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">Agência</h6>
                <h6 class="font-size-16 font-weight-normal">
                  {{ enviar.agency }}<template v-if="enviar.agency_digit">-{{ enviar.agency_digit }}</template>
                </h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16">Conta</h6>
                <h6 class="font-size-16 font-weight-normal">{{ enviar.account }}-{{ enviar.account_digit }}</h6>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-16 mb-0">Quando</h6>
                <h6 class="font-size-16 mb-0 font-weight-normal">Hoje</h6>
              </div>
            </div>
          </div>
          <div class="card bg-light">
            <div class="card-body bg-white m-2 rounded">
              <div class="d-flex justify-content-between">
                <h6 class="font-size-15">Valor sendo transferido</h6>
                <h6 class="font-size-15 font-weight-normal">{{ enviar.value }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-15">Taxa</h6>
                <h6 class="font-size-15 font-weight-normal">{{ enviar.tax | currency }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="font-size-15 mb-0">Total a pagar</h6>
                <h6 class="font-size-15 mb-0 font-weight-normal">{{ enviar.value }}</h6>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="font-size-14 mb-0">Saldo disponível em conta</h6>
            <div class="d-flex align-items-center">
              <h6 v-if="enviar.show" class="font-size-16 font-weight-normal mb-0">{{ this.enviar.balance }}</h6>
              <h6 v-else class="font-size-16 font-weight-normal mb-0">R$ ***,**</h6>
              <button v-if="enviar.show" class="border-0 rounded-circle ml-2 p-0 bg-white"
                v-on:click="enviar.show = false">
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.5 12a3.5 3.5 0 1 1 7.001.001A3.5 3.5 0 0 1 8.5 12Zm2 0a1.501 1.501 0 0 0 3 0 1.501 1.501 0 0 0-3 0Z"
                    fill="currentColor"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2 12s2.955-7 10-7 10 7 10 7-2.955 7-10 7-10-7-10-7Zm10 5c2.304 0 4.354-.933 6.093-2.771A12.146 12.146 0 0 0 19.75 12a12.145 12.145 0 0 0-1.657-2.229C16.353 7.932 14.303 7 12 7c-4.369 0-6.843 3.418-7.753 4.995.338.583.888 1.416 1.66 2.234C7.647 16.067 9.697 17 12 17Z"
                    fill="currentColor"></path>
                </svg>
              </button>
              <button v-else class="border-0 rounded-circle ml-2 p-0 bg-white" v-on:click="enviar.show = true">
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="m3.78 19.5-1.414-1.42 2.338-2.35C2.844 13.86 2 11.852 2 11.852s2.955-7.034 10-7.034c1.13 0 2.156.182 3.08.486L18.367 2l1.414 1.422-16 16.078Zm.48-7.652c.338.586.888 1.425 1.663 2.25l.126.128.076.077 2.378-2.39v-.03l-.001-.03a3.51 3.51 0 0 1 3.5-3.518h.03l.03.001 1.373-1.38a8.04 8.04 0 0 0-1.433-.128c-4.364 0-6.836 3.436-7.742 5.02Z"
                    fill="currentColor"></path>
                  <path
                    d="m18.667 7.384-1.422 1.43c.286.243.565.507.835.795.772.821 1.322 1.66 1.66 2.245a12.28 12.28 0 0 1-1.66 2.244c-1.737 1.846-3.782 2.78-6.08 2.78a7.82 7.82 0 0 1-2.41-.373l-1.55 1.56a9.71 9.71 0 0 0 3.96.825c7.045 0 10-7.035 10-7.035s-1.033-2.461-3.333-4.471Z"
                    fill="currentColor"></path>
                  <path
                    d="M12.002 15.37a3.51 3.51 0 0 0 3.5-3.518c0-.387-.064-.76-.179-1.108L10.9 15.19c.347.116.718.18 1.103.18Z"
                    fill="currentColor"></path>
                </svg>
              </button>
            </div>
          </div>
          <div class="d-flex mt-5">
            <b-button :disabled="!this.enviar.name ||
              !this.enviar.person_type ||
              !this.enviar.person_number ||
              !this.enviar.bank ||
              !this.enviar.agency ||
              !this.enviar.account ||
              !this.enviar.account_digit ||
              !this.enviar.account_type ||
              !this.enviar.value" variant="default" v-on:click="enviar.progress = 5; enviarTransferencia();">
              Enviar transferência
            </b-button>
            <button class="btn btn-outline-link ml-3" v-on:click="enviar.progress = 3">Voltar</button>
            <button class="btn btn-outline-link" v-on:click="clearTransferencia()">Cancelar
              <sup>ESC</sup></button>
          </div>
        </template>
        <template v-else-if="enviar.progress == 5">
          <div class="py-4 text-center">
            <b-spinner big class="ml-2 align-middle" variant="default" role="status"></b-spinner><br>
            <h6 class="my-4">Enviando transferência</h6>
          </div>
        </template>
        <template v-else-if="enviar.progress == 6">
          <div class="pt-4">
            <div class="d-inline-block bg-soft-success rounded p-2 mb-3">
              <i class="bx bx-lock-alt font-size-22 align-middle"></i>
            </div>
            <h5>Token de validação</h5>
            <h6>Para um cuidado extra com a segurança da sua conta, enviamos um token para seu e-mail cadastrada para
              validação da sua transação.</h6>
            <b-form class="mt-5">
              <b-form-group label="Código" label-for="code">
                <b-form-input id="code" v-model="enviar.code" type="text" autocomplete="off"
                  v-mask="'######'"></b-form-input>
              </b-form-group>
            </b-form>
          </div>
          <div class="d-flex mt-5">
            <b-button variant="default" v-on:click="confirmarTransferencia()">Validar</b-button>
            <button class="btn btn-outline-link" v-on:click="clearTransferencia()">Cancelar
              <sup>ESC</sup></button>
          </div>
        </template>
        <template v-else-if="enviar.progress == 7">
          <div class="py-4 text-center">
            <b-spinner big class="ml-2 align-middle" variant="default" role="status"></b-spinner><br>
            <h6 class="my-4">Validando a transferência</h6>
          </div>
        </template>
        <template v-else-if="enviar.progress == 8">
          <div class="pt-4">
            <div class="d-inline-block bg-soft-danger rounded p-2 mb-3">
              <i class="bx bx-error font-size-22 align-middle"></i>
            </div>
            <h5>Transação não realizada</h5>
            <h6>Não foi possível realizar a transação e ela não foi finalizada.</h6>
          </div>
          <div class="d-flex mt-5">
            <b-button variant="default"
              v-on:click="clearTransferencia(); enviar.modal = true; enviar.progress = 1;">Tente novamente</b-button>
            <button class="btn btn-outline-link" v-on:click="clearTransferencia()">Fechar <sup>ESC</sup></button>
          </div>
        </template>
        <template v-else-if="enviar.progress == 9">
          <div class="pt-4">
            <div class="d-inline-block bg-soft-success rounded p-2 mb-3">
              <i class="bx bx-badge-check font-size-22 align-middle"></i>
            </div>
            <h5>Transação realizada com sucesso</h5>
          </div>
          <div class="d-flex mt-5">
            <button class="btn btn-outline-link" v-on:click="clearTransferencia()">Fechar</button>
          </div>
        </template>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.pix-receber .card-body {
  background-image: linear-gradient(to right, rgb(13, 199, 139) 8px, transparent 8px, transparent 100%);
  border-radius: 6px;
  padding-left: 25px;
}

.pix-enviar .card-body {
  background-image: linear-gradient(to right, rgb(255, 154, 134) 8px, transparent 8px, transparent 100%);
  border-radius: 6px;
  padding-left: 25px;
}

#code {
  font-size: 1.4em;
  letter-spacing: 2px;
}
</style>